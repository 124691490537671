<template>
  <v-container id="BollettaInterattivaDownload5Dt" class="pa-7">
    <v-row class="d-flex" justify="center" align="center">
      <v-card class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaDownload5Dt d-flex" justify="left" align="left" >
            <v-col cols="2">
              <img :onerror="hideParent()" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-download.svg')" />
            </v-col>
            <v-col cols="10" class="">
                <span class="downloadText" v-html='translateLabel("message.bolletta_interattiva_template5_downloadText")'></span>
            </v-col>
        </v-row>                     
        <v-row class="SecondContentBollettaInterattivaDownload5Dt d-flex" justify="center" align="center" >
          <v-btn
              class="bollettaInterattiva3_downloadButton white--text v-btn-shadow" id="bollettaInterattiva"
                @click="downloadFile"
                x-large
                color="black"
                >
                <span class="bollettaInterattiva5_downloadButton_content"
                 v-html="$t('message.bolletta_interattiva_template5_download_button_label')"></span> 
          </v-btn>
        </v-row>
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'
import utility from '../Core/utility';

export default {
  mixins: [utility],
 computed: {

  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            window.open(url, '_blank');
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        })               
        .catch(error => {
            console.log(error);
        });
      },
  }

}
</script>

<style>

</style>